import { render, staticRenderFns } from "./CityG.vue?vue&type=template&id=06f1be8f&scoped=true&"
import script from "./CityG.vue?vue&type=script&lang=js&"
export * from "./CityG.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/CityGe.styl?vue&type=style&index=0&id=06f1be8f&lang=stylus&scoped=true&"
import style1 from "./CityG.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f1be8f",
  null
  
)

export default component.exports