<template>
  <div>
    <el-container>
      <el-header style="padding: 0" height="100%">
        <vheadere class="hidden-sm-and-down"></vheadere>
        <ivheaderae class="hidden-md-and-up"></ivheaderae>
      </el-header>
      <el-main style="padding: 0">
        <div class="G-container en">
          <div class="m-nav hidden-md-and-up">
            <div class="m-header">
              <img class="m-title" src="../../assets/images/G/header-title-en.jpg" alt="" />
              <div class="m-exit-btn" @click="exitLogin">
                <span>Sign Out</span>
                <img src="../../assets/images/G/m-exit.png" alt="" />
              </div>
            </div>
            <div class="m-photo">
              <el-avatar :size="80" :src="photo"></el-avatar>
              <div class="name">{{ name == undefined || name == null || name == '' ? loginname : name }}</div>
              <!--              <div class="sign">我只是一个简介</div>-->
            </div>
            <div class="nav-list">
              <ul>
                <li @click="mNavSel('0')">
                  <img :src="this.navName === '0' ? require('../../assets/images/G/m-nav-11.png') : require('../../assets/images/G/m-nav-1.png')" alt="" />
                  <p :class="this.navName === '0' ? 'is-active' : ''">Personal Homepage</p>
                </li>
                <li @click="mNavSel('1')">
                  <img :src="this.navName === '1' ? require('../../assets/images/G/m-nav-22.png') : require('../../assets/images/G/m-nav-2.png')" alt="" />
                  <p :class="this.navName === '1' ? 'is-active' : ''">Live Events</p>
                </li>
                <li @click="mNavSel('2')">
                  <img :src="this.navName === '2' ? require('../../assets/images/G/m-nav-33.png') : require('../../assets/images/G/m-nav-3.png')" alt="" />
                  <p :class="this.navName === '2' ? 'is-active' : ''">Registered Events</p>
                </li>
                <li @click="mNavSel('3')">
                  <img :src="this.navName === '3' ? require('../../assets/images/G/m-nav-44.png') : require('../../assets/images/G/m-nav-4.png')" alt="" />
                  <p :class="this.navName === '3' ? 'is-active' : ''">Favourite Events</p>
                </li>
                <li @click="mNavSel('4')">
                  <img :src="this.navName === '4' ? require('../../assets/images/G/m-nav-55.png') : require('../../assets/images/G/m-nav-5.png')" alt="" />
                  <p :class="this.navName === '4' ? 'is-active' : ''">Following Organizers</p>
                </li>
                <li @click="mNavSel('5')">
                  <img :src="this.navName === '5' ? require('../../assets/images/G/m-nav-66.png') : require('../../assets/images/G/m-nav-6.png')" alt="" />
                  <p :class="this.navName === '5' ? 'is-active' : ''">Organizers Certification</p>
                </li>
                <li @click="mNavSel('6')">
                  <img :src="this.navName === '6' ? require('../../assets/images/G/m-nav-77.png') : require('../../assets/images/G/m-nav-7.png')" alt="" />
                  <p :class="this.navName === '6' ? 'is-active' : ''">My Account</p>
                </li>
                <li @click="mNavSel('7')">
                  <img :src="this.navName === '7' ? require('../../assets/images/G/m-nav-88.png') : require('../../assets/images/G/m-nav-8.png')" alt="" />
                  <p :class="this.navName === '7' ? 'is-active' : ''">Change Password</p>
                </li>
              </ul>
            </div>
          </div>
          <div v-show="fixNav" class="fixed-nav hidden-md-and-up">
            <el-col :span="8">
              <div class="user-phone">
                <el-avatar size="small" :src="photo"></el-avatar>
                <span class="name">{{ name == undefined || name == null || name == '' ? loginname : name }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div v-show="!moreShow" @click="moreBtn" class="more-btn">
                <i class="el-icon-copy-document"></i>
                <span>More</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div v-show="!moreShow" class="exit-btn" @click="exitLogin">
                <span>Sign Out</span>
                <img width="15%" src="../../assets/images/G/m-exit2.png" alt="" />
              </div>
              <div v-show="moreShow" class="close-btn" @click="moreClose">
                <span>Close</span>
                <i class="el-icon-close"></i>
              </div>
            </el-col>
          </div>
          <div class="fix-nav-content" v-show="moreShow">
            <ul>
              <li @click="mNavSel('0')">
                <img src="../../assets/images/G/m-nav-1.png" alt="" />
                <span>Personal Homepage</span>
              </li>
              <li @click="mNavSel('1')">
                <img src="../../assets/images/G/m-nav-2.png" alt="" />
                <span>Live Events</span>
              </li>
              <li @click="mNavSel('2')">
                <img src="../../assets/images/G/m-nav-3.png" alt="" />
                <span>Registered Events</span>
              </li>
              <li @click="mNavSel('3')">
                <img src="../../assets/images/G/m-nav-4.png" alt="" />
                <span>Favourite Events</span>
              </li>
              <li @click="mNavSel('4')">
                <img src="../../assets/images/G/m-nav-5.png" alt="" />
                <span>Following Organizers</span>
              </li>
              <li @click="mNavSel('5')">
                <img src="../../assets/images/G/m-nav-6.png" alt="" />
                <span>Organizers Certification</span>
              </li>
              <li @click="mNavSel('6')">
                <img src="../../assets/images/G/m-nav-7.png" alt="" />
                <span>My Account</span>
              </li>
              <li @click="mNavSel('7')">
                <img src="../../assets/images/G/m-nav-8.png" alt="" />
                <span>Change Password</span>
              </li>
              <div class="exit-btn" @click="exitLogin">
                <span>Sign Out</span>
                <img src="../../assets/images/G/m-exit.png" alt="" />
              </div>
            </ul>
          </div>
          <div v-show="true" class="header hidden-sm-and-down">
            <div class="w">
              <div class="header-content">
                <img src="../../assets/images/G/header-title-en.jpg" alt="" />
                <div class="user-card">
                  <ul>
                    <li class="user-info">
                      <el-avatar size="medium" :src="photo"></el-avatar>
                      <span class="user-name" ref="username">{{ name == undefined || name == null || name == '' ? loginname : name }}</span>
                    </li>
                    <li class="exit-btn" @click="exitLogin">
                      <!--                            <Icon type="android-exit" style="width: 30px;height: 30px"></Icon>-->
                      <img src="../../assets/images/G/exit.png" alt="" />
                      <span>Sign Out</span>
                    </li>
                    <li>
                      <el-popover
                        placement="bottom"
                        width="300"
                        trigger="hover"
                        content="Only users certified as organizers can create an event">
                        <div class="btn" slot="reference" @click="btn4">
                          <i class="el-icon-s-flag"></i>
                          <span>Create Events for Free</span>
                        </div>
                      </el-popover>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div v-show="true" class="content-box">
            <div class="w">
              <div class="content">
                <el-tabs tab-position="left" @tab-click="tabSwitch" v-model="navName">
                  <el-tab-pane label="Personal Homepage" name="0"><router-view v-if="navName === '0'" ref="cityG1"></router-view></el-tab-pane>
                  <el-tab-pane label="Live Events" name="1"><router-view v-if="navName === '1'" ref="cityG2"></router-view></el-tab-pane>
                  <el-tab-pane label="Registered Events" name="2"><router-view v-if="navName === '2'" ref="cityG3"></router-view></el-tab-pane>
                  <el-tab-pane label="Favourite Events" name="3"><router-view v-if="navName === '3'" ref="cityG4"></router-view></el-tab-pane>
                  <el-tab-pane label="Following Organizers" name="4"><router-view v-if="navName === '4'" ref="cityG5"></router-view></el-tab-pane>
                  <el-tab-pane label="Organizers Certification" name="5"><router-view v-if="navName === '5'" ref="cityG6"></router-view></el-tab-pane>
                  <el-tab-pane label="My Account" name="6"><router-view v-if="navName === '6'" ref="cityG7"></router-view></el-tab-pane>
                  <el-tab-pane label="Change Password" name="7"><router-view v-if="navName === '7'" ref="cityG8"></router-view></el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <vfootere class="hidden-sm-and-down"></vfootere>
        <ivfootere class="hidden-md-and-up"></ivfootere>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import { getUserData } from '../../api'
import qs from 'qs'
export default {
  data () {
    return {
      navName: '',
      phone: '',
      imageUrl: '',
      loginname: '',
      name: '',
      photo: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      userName: '',
      moreShow: false,
      fixNav: false,
      initiatelist: [],
      collist: [],
      sponsorList: [],
      joinlist: [],
      activityList: [],
      participatelist: [],
      indexNum1: '',
      indexNum2: '',
      indexNum3: '',
      indexNum4: '',
      startX:'',
      startY:''
    }
  },
  inject: ['reload'],
  methods: {
    moreBtn () {
      window.removeEventListener('scroll', this.scrollListener)
      this.moreShow = true
      $('html,body').css({
        'overflow': 'hidden',
        'height': '100%'
      })
    },
    moreClose () {
      this.moreShow = false
      window.addEventListener('scroll', this.scrollListener)
      $('html,body').css({
        'overflow': 'unset',
        'height': 'unset'
      })
    },
    exitLogin () {
      window.addEventListener('scroll', this.scrollListener)
      $('html,body').css({
        'overflow': 'unset',
        'height': 'unset'
      })
      let userInfo = {
        'userId': '97d7581471354cf4a84ec06218da7ad2',
        'userName': 'yk'
      }
      userInfo = JSON.stringify(userInfo)
      localStorage.setItem('userInfo', userInfo)
      this.$router.push('/LoginEN')
    },
    btn4 () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo.userName == 'yk') {
        this.$confirm('To log in, please click the button below', 'Tips', {
          confirmButtonText: 'Confirm',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/LoginEN')
        })
      } else {
        this.$router.push('/createEN')
      }
    },
    tabSwitch (tab) {
      // console.log(tab.name)
      if (tab.name === '0') {
        this.$router.push({
          name: 'cityG1e'
        })
      } else if (tab.name === '1') {
        this.$router.push({
          name: 'cityG2e'
        })
      } else if (tab.name === '2') {
        this.$router.push({
          name: 'cityG3e'
        })
      } else if (tab.name === '3') {
        this.$router.push({
          name: 'cityG4e'
        })
      } else if (tab.name === '4') {
        this.$router.push({
          name: 'cityG5e'
        })
      } else if (tab.name === '5') {
        this.$router.push({
          name: 'cityG6e'
        })
      } else if (tab.name === '6') {
        this.$router.push({
          name: 'cityG7e'
        })
      } else if (tab.name === '7') {
        this.$router.push({
          name: 'cityG8e'
        })
      }
    },
    mNavSel (i) {
      window.addEventListener('scroll', this.scrollListener)
      $('html,body').css({
        'overflow': 'unset',
        'height': 'unset'
      })
      this.moreShow = false
      this.navName = i
      if (i === '0') {
        this.$router.push({
          name: 'cityG1e'
        })
      } else if (i === '1') {
        this.$router.push({
          name: 'cityG2e'
        })
      } else if (i === '2') {
        this.$router.push({
          name: 'cityG3e'
        })
      } else if (i === '3') {
        this.$router.push({
          name: 'cityG4e'
        })
      } else if (i === '4') {
        this.$router.push({
          name: 'cityG5e'
        })
      } else if (i === '5') {
        this.$router.push({
          name: 'cityG6e'
        })
      } else if (i === '6') {
        this.$router.push({
          name: 'cityG7e'
        })
      } else if (i === '7') {
        this.$router.push({
          name: 'cityG8e'
        })
      }
    },
    test (e) {
      console.log(e)
      console.log(e.name)
    },
    scrollListener () {
      let navObj = document.getElementsByClassName('nav-list')[0]
      // console.log(this.getElementTopLeft(navObj).top + navObj.clientHeight > window.pageYOffset)
      if (this.getElementTopLeft(navObj).top + navObj.clientHeight > window.pageYOffset) {
        this.fixNav = false
      } else {
        this.fixNav = true
      }
    },
    getElementTopLeft (obj) {
      var top = 0
      var left = 0
      while (obj) {
        top += obj.offsetTop
        left += obj.offsetLeft
        obj = obj.offsetParent
      }
      return {
        top: top,
        left: left
      }
    },
    goBack () {
      this.$nextTick(() => {
        // console.log(this.$route.name)
        if (this.$route.name === 'cityG1e') {
          this.navName = '0'
        } else if (this.$route.name === 'cityG2e') {
          this.navName = '1'
        } else if (this.$route.name === 'cityG2Ae') {
          this.navName = '1'
        } else if (this.$route.name === 'cityG2be') {
          this.navName = '1'
        } else if (this.$route.name === 'cityG3e') {
          this.navName = '2'
        } else if (this.$route.name === 'cityG4e') {
          this.navName = '3'
        } else if (this.$route.name === 'cityG5e') {
          this.navName = '4'
        } else if (this.$route.name === 'cityG6e') {
          this.navName = '5'
        } else if (this.$route.name === 'cityG7e') {
          this.navName = '6'
        } else if (this.$route.name === 'cityG8e') {
          this.navName = '7'
        }
      })
    },
    touchstart(e){
      this.startX = e.targetTouches[0].pageX;
      this.startY = e.targetTouches[0].pageY;
    },
    touchmove(e){
      var moveX = e.targetTouches[0].pageX;
      var moveY = e.targetTouches[0].pageY;
      if (Math.abs(moveX - this.startX) > Math.abs(moveY - this.startY)) {
        e.preventDefault();
      }
    }
    // backTop () {
    //   let that = this
    //   let timer = setInterval(() => {
    //     let ispeed = Math.floor(-that.scrollTop / 5)
    //     document.documentElement.scrollTop = document.body.scrollTop =
    //       that.scrollTop + ispeed
    //     if (that.scrollTop === 0) {
    //       clearInterval(timer)
    //     }
    //   }, 16)
    // }
  },
  mounted () {
    window.addEventListener("touchstart", this.touchstart);
    window.addEventListener("touchmove", this.touchmove, {
      passive: false
    });
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    console.log(userInfo)
    if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
      this.$confirm('To log in, please click the button below', 'Tips', {
        confirmButtonText: 'confirm',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
        this.$router.push('/LoginEN')
      })
    } else {
      this.loginname = userInfo.userName
      let data = {
        SPONSOR_ID: userInfo.userId
      }
      getUserData(qs.stringify(data)).then(res => {
        this.name = res.data.new.NAME
        if (res.data.new.IMAGE != undefined || res.data.new.IMAGE != null) {
          this.photo = res.data.new.IMAGE
        }
        this.userName = res.data.new.USERNAME
      })
      window.addEventListener('scroll', this.scrollListener)
      this.$nextTick(() => {
        // console.log(this.$route.name)
        if (this.$route.name === 'cityG1e') {
          this.navName = '0'
        } else if (this.$route.name === 'cityG2e') {
          this.navName = '1'
        } else if (this.$route.name === 'cityG2Ae') {
          this.navName = '1'
        } else if (this.$route.name === 'cityG2be') {
          this.navName = '1'
        } else if (this.$route.name === 'cityG3e') {
          this.navName = '2'
        } else if (this.$route.name === 'cityG4e') {
          this.navName = '3'
        } else if (this.$route.name === 'cityG5e') {
          this.navName = '4'
        } else if (this.$route.name === 'cityG6e') {
          this.navName = '5'
        } else if (this.$route.name === 'cityG7e') {
          this.navName = '6'
        } else if (this.$route.name === 'cityG8e') {
          this.navName = '7'
        }
      })
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL)
        window.addEventListener('popstate', this.goBack, false)
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollListener)
    window.removeEventListener('touchstart',this.touchstart)
    window.removeEventListener('touchmove',this.touchmove)
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val)
        // console.log(oldVal)
        if (val.name === 'cityG2e') {
          this.navName = '1'
        } else if (val.name === 'cityGe') {
          this.navName = '0'
        }else if (val.name === 'cityG2Ae') {
          this.navName = '1'
        }else if (val.name === 'cityG2Be') {
          this.navName = '1'
        }else if (val.name === 'cityG1e') {
          this.navName = '0'
        } else if (val.name === 'cityG3e') {
          this.navName = '2'
        } else if (val.name === 'cityG4e') {
          this.navName = '3'
        } else if (val.name === 'cityG5e') {
          this.navName = '4'
        } else if (val.name === 'cityG6e') {
          this.navName = '5'
        } else if (val.name === 'cityG7e') {
          this.navName = '6'
        } else if (val.name === 'cityG8e') {
          this.navName = '7'
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped src="@/assets/css/CityGe.styl">

</style>
<style lang="stylus">
.G-container.en
  .el-avatar
    background transparent
  .content-box
    .content
      .el-input__inner
        border-radius 0
      /*.el-form--label-left .clear-indent .el-form-item__label*/
      /*  text-indent 0.75em*/
      .user-data
        .el-upload__text
          position absolute
          bottom 0
          left 50%
          margin-left -45px
        .el-button--success
          background-color: #A5CD39;
          border-color: #A5CD39;
          border-radius 0
      .user-data .align-center .el-form-item__content
        margin-left 0!important
      .user-data .text-center .el-form-item__content
        text-align center
        margin-top 1rem
      .distpicker-address-wrapper select
        padding: 0 15px;
        font-size: inherit;
        border-radius: 0;
      .el-tabs--left .el-tabs__header.is-left
        margin-right 0.35rem
      .el-tabs__nav-wrap::after
        background none
      .el-tabs__active-bar
        display none
      .el-tabs__item
        margin 0.2rem 0
      .el-tabs--left .el-tabs__item.is-left
        text-align left
      .el-tabs__item:hover
        color #A5CD39
      .el-tabs__item.is-active
        color #A5CD39
        &:after
          content ''
          position absolute
          bottom 0
          left 10%
          width 60%
          border-top 0.03rem solid #a5cd39
      #tab-0.is-active
        &:after
          width 71%
      #tab-1.is-active
        &:after
          width 53%
      #tab-2.is-active
        &:after
          width 58%
      #tab-3.is-active
        &:after
          width 58%
      #tab-4.is-active
        &:after
          width 70%
      #tab-5.is-active
        &:after
          width 80%
      #tab-6.is-active
        &:after
          width 43%
      #tab-7.is-active
        &:after
          width 64%
      .el-tabs__content
        min-height: 11rem;
        position relative
        padding-left 0.85rem
        padding-bottom: 2rem;
        &:after
          content ''
          position absolute
          top:2%
          left 0
          height 90%
          border-right 1px solid #C9CACA
      .user-data
        .drag_verify .dv_handler
          width 44px !important
          height 44px !important
        .el-form-item__content{
          text-align left
        }
        .avatar-uploader .el-upload {
          border: 1px solid #d9d9d9;
          border-radius: 0px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
          border-color: #409EFF
          .el-upload__text{
            color #409EFF
          }
        }
        .avatar-uploader-icon {
          font-size: 1rem;
          color: #8c939d;
          width: 2.2rem;
          height: 2.2rem;
          line-height: 2.2rem;
          text-align: center;
        }
        .avatar {
          width: 2.2rem;
          height: 2.2rem;
          display: block;
        }

      .main-p{
        .el-pagination{
          text-align center
        }
        .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
          margin: 0;
          border-radius: 0;
          border 0.0185rem solid #e2e2e2
          background #fff
          padding 0 .24rem
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active{
          background #a5cd53
          border 0.0185rem solid #a5cd53
        }
        .el-icon-arrow-left:before{
          content 'Prev'
          font-weight normal
        }
        .el-icon-arrow-right:before{
          content 'Next'
          font-weight normal
        }
        &.main-p6,&.main-p8{
          .p6-form{
            padding .4rem 0 0 0
            .avatar-uploader .el-upload {
              border: 0.0185rem solid #c0c4cc;
              cursor: pointer;
              position: relative;
              overflow: hidden;
            }
            .avatar-uploader .el-upload:hover {
              border-color: #409EFF;
            }
            .tx-upload{
              .el-upload-dragger{
                width 2.1296rem
                height 2.1296rem
                line-height 2.1296rem
                border none
                .el-icon-upload{
                  margin 0.3703rem 0 0
                  font-size .952rem
                  .el-upload__text{
                    padding 0 .2rem
                  }
                }
              }
            }
            .tx-upload-tip{
              position absolute
              width max-content
              top 1.7rem
              left 3rem
              color #8d8d8d
              font-size 0.2592rem
            }
            .avatar-uploader-icon {
              font-size: 0.8185rem;
              color: #8c939d;
              width: 2.1296rem;
              height: 2.1296rem;
              line-height: 1.6296rem;
              text-align: center;
              .el-upload__text{
                line-height initial
                margin 0
              }
            }
            .avatar {
              width: 2.1296rem;
              height: 2.1296rem;
              display: block;
            }
            .el-form-item__label::before{
              color #ffa300 !important
            }
            .el-upload-dragger{
              border-radius 0
              border-style solid
            }
            .el-form-item.tx-form{
              display flex
              align-items center
              position relative
              .el-form-item__content{
                margin-left 0 !important
              }
            }
            .el-input__inner,.el-textarea__inner{
              border-radius 0
              border-color #c0c4cc
            }
            .el-upload__text{
              line-height 1.4
              font-size 0.2592rem
            }
          }
        }
        &.main-p6{
          .p6-item-fra{
            text-align right
            .el-form-item{
              display inline-block
              &:nth-child(1){
                width 65%
              }
              &:nth-child(2){
                width 35%
                .p6-select{
                  display block
                }
                .el-form-item__label {
                  text-align center
                }
              }
            }
          }
        }
        &.main-p8{
          .p6-form{
            .el-input{
              width 5.5555rem
            }
            .dv_handler{
              width 44px !important
              height 44px !important
              i{
                line-height 2
              }
            }
          }
        }
      }
@media screen and (max-width:990px) {
  .G-container.en .content-box .content .el-tabs--left .el-tabs__header.is-left{
    display none
  }
  .G-container.en .content-box .content .el-tabs__content{
    padding  0
    min-height 8rem
  }
  .G-container.en .content-box .content .el-tabs__content:after{
    content unset
  }
}
</style>
